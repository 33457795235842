import React from "react"
import Layout from "../../components/layout"
import { Container } from "reactstrap"
import Head from "../../components/head"
import { Link } from "gatsby"



// const liStyle = {
//     display: "list-item",
//     listStyleType: "disc",
//     paddingBottom: ".5rem",
//     marginLeft: "2rem",
//     fontSize: "14px",
//     color: "#777777",
// }

const strong = {
  fontWeight: "bold",
}

const Available = () => {
  return (
    <Layout>
      <Head title="Ventriloquist Dummies For Sale" description="Ready to buy a ventriloquist dummy? ThrowThings has so many dummies in stock and ready to ship! Even more are almost ready!" />
      <Container>
        <section className="about-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner-section"></div>
              </div>
              <div className="col-sm-12">
                <h4>VENTRILOQUIST DUMMIES FOR SALE</h4>
                <p>Ready to buy a ventriloquist dummy? ThrowThings still has dummies and puppets in stock and ready to ship!</p>
                <p>Those dummies that are available to ship immediately as
                  of the time of this posting on July 24, 2024, are shown below as noted.<span style={strong}>IF YOU HAVE AN EVENT AND NEED YOUR VENTRILOQUIST DUMMY SENT FOR SCHEDULED
                    DELIVERY BY A CERTAIN DATE PLEASE FEEL FREE TO CONTACT US TO CHECK ON THE CURRENT STOCK STATUS AND SHIPPING TIMES.</span>
                </p>
                <p>We will update this list and the rest of our site as often as possible. The list below is accurate as of the time of posting on July 24, 2024.</p>
                <Link to="/dummies/upgrades">Click Here to find out about our basic ventriloquist dolls and our upgraded ventriloquist dummies and what makes them so special.</Link>

                {/* <!-- section start --> */}
                <section className="section-b-space sitemap_page">
                  <div className="container">
                    <div className="row">
                      {/*<div className="col-md-4">
                        <ul>
                          {/*<li>
                            <Link to="/basic-ventriloquist-dummies">
                              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                              Basic Ventriloquist Dummies
                            </Link>
                            <ul>
                              <li>
                                <Link to="/products/charlie-mccarthy-basic-ventriloquist-dummy-doll">
                                  Charlie McCarthy Basic Dummy
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/groucho-marx-basic-ventriloquist-dummy-doll">
                                  Groucho Marx Basic Dummy
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/w-c-fields-basic-ventriloquist-dummy-doll">
                                  W.C. Fields Basic Dummy - OUT OF STOCK
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/mortimer-snerd-basic-ventriloquist-dummy-doll">
                                  Mortimer Snerd Basic Dummy
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/cemmett-kelly-jr-basic-ventriloquist-dummy-doll">Emmett Kelly Jr Basic Dummy</Link>
                              </li>
                              <li>
                                <Link to="/products/howdy-doody-basic-ventriloquist-dummy-doll">Howdy Doody Basic Dummy</Link>
                              </li>
                              <li>
                                <Link to="/products/bozo-the-clown-basic-ventriloquist-dummy-doll">
                                  Bozo The Clown Basic Dummy
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/danny-o-day-basic-ventriloquist-dummy-doll">
                                  Danny O'Day Basic Dummy
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/slappy-from-goosebumps-basic-ventriloquist-dummy">
                                  Slappy From Goosebumps Basic Dummy
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/oliver-hardy-basic-ventriloquist-dummy-doll">
                                  Oliver Hardy Basic Dummy - OUT OF STOCK
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/stan-laurel-basic-ventriloquist-dummy-doll">
                                  Stan Laurel Basic Dummy - OUT OF STOCK
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/lester-basic-ventriloquist-dummy-doll">Lester Basic Dummy</Link>
                              </li>
                            </ul>
                          </li>*/}

                      {/*<li>
                            <Link to="/standard-upgrade-ventriloquist-dummies">
                              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                              Standard Upgrade Ventriloquist Dummies
                            </Link>
                            <ul>
                              {/*<li>
                                <Link to="/products/charlie-mccarthy-standard-upgrade-dummy">
                                  Charlie McCarthy Standard Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/groucho-marx-standard-upgrade-dummy">
                                  Groucho Marx Standard Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/wc-fields-standard-upgrade-dummy">
                                  W.C. Fields Standard Upgrade-OUT OF STOCK
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/mortimer-snerd-standard-upgrade-dummy">
                                  Mortimer Snerd Standard Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/emmett-kelly-jr-standard-upgrade-dummy">Emmett Kelly Jr Standard Upgrade</Link>
                              </li>
                              {/*<li>
                                <Link to="/products/howdy-doody-standard-upgrade-dummy">Howdy Doody Standard Upgrade</Link>
                              </li>
                              <li>
                                <Link to="/products/bozo-the-clown-standard-upgrade-dummy">
                                  Bozo The Clown Standard Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/danny-o-day-standard-upgrade-dummy">
                                  Danny O'Day Standard Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/gramps-granny">
                                  Slappy From Goosebumps Standard Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/oliver-hardy-standard-upgrade-dummy">
                                  Oliver Hardy Standard Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/stan-laurel-standard-upgrade-dummy">
                                  Stan Laurel Standard Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/lester-standard-upgrade-dummy">Lester Standard Upgrade</Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                        </div>
                      <div className="col-md-4">
                        <ul>
                          {/*<li>
                            <Link to="/deluxe-upgrade-ventriloquist-dummies">
                              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                              Deluxe Upgrade Ventriloquist Dummies
                            </Link>
                            <ul>
                              {/*<li>
                                <Link to="/products/charlie-mccarthy-deluxe-upgrade-dummy">
                                  Charlie McCarthy Deluxe Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/groucho-marx-deluxe-upgrade-dummy">
                                  Groucho Marx Deluxe Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/wc-fields-deluxe-upgrade-dummy">
                                  W.C. Fields Deluxe Upgrade-OUT OF STOCK
                                </Link>
                              </li>
                              <li>
                                <Link to="/mortimer-snerd-deluxe-upgrade-dummy">
                                  Mortimer Snerd Deluxe Upgrade
                                </Link>
                            </li>
                              <li>
                                <Link to="/products/emmett-kellly-jr-deluxe-upgrade-dummy">Emmett Kelly Jr Deluxe Upgrade</Link>
                              </li>
                              <li>
                                <Link to="/products/howdy-doody-deluxe-upgrade-dummy">Howdy Doody Deluxe Upgrade</Link>
                              </li>
                              <li>
                                <Link to="/products/bozo-the-clown-deluxe-upgrade-dummy">
                                  Bozo The Clown Deluxe Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/danny-o-day-deluxe-upgrade-dummy">
                                  Danny O'Day Deluxe Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/slappy-from-goosebumps-deluxe-upgrade-dummy">
                                  Slappy From Goosebumps Deluxe Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/slappy-special-deluxe-upgrade-dummy">
                                  Slappy Special Deluxe Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/auburn-slappy-deluxe-upgrade-dummy">
                                  Slappy Auburn Deluxe Upgrade
                                </Link>
                          </li>
                              <li>
                                <Link to="/products/lester-deluxe-upgrade-dummy">Lester Deluxe Upgrade</Link>
                              </li>
                            </ul>
                          </li>

                      <li>
                            <Link to="/super-deluxe-upgrade-ventriloquist-dummies">
                              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                              Super Deluxe Upgrade Dummies
                            </Link>
                            <ul>
                              <li>
                                <Link to="/products/charlie-mccarthy-super-deluxe-upgrade-dummy">
                                  Charlie McCarthy Super Deluxe Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/groucho-marx-super-deluxe-upgrade-dummy">
                                  Groucho Marx Super Deluxe Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/wc-fields-super-deluxe-upgrade-dummy">
                                  W.C. Fields Super Deluxe Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/mortimer-snerd-super-deluxe-upgrade-dummy">
                                  Mortimer Snerd Super Deluxe Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/emmett-kelly-jr-super-deluxe-upgrade-dummy">Emmett Kelly Jr Super Deluxe Upgrade</Link>
                              </li>
                              <li>
                                <Link to="/products/howdy-doody-super-deluxe-upgrade-dummy">Howdy Doody Super Deluxe Upgrade</Link>
                              </li>
                              <li>
                                <Link to="/products/bozo-the-clown-super-deluxe-upgrade-dummy">
                                  Bozo The Clown Super Deluxe Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/danny-o-day-super-deluxe-upgrade-dummy">
                                  Danny O'Day Super Deluxe Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/slappy-from-goosebumps-super-deluxe-upgrade-dummy">
                                  Slappy From Goosebumps Super Deluxe Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/auburn-slappy-super-deluxe-upgrade-dummy">
                                  Slappy Auburn Super Deluxe Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/lester-super-deluxe-upgrade-dummy">
                                  Lester Super Deluxe Upgrade
                                </Link>
                              </li>
                            </ul>
                        </li>
                    </ul>
                  </div>*/}

                      <div className="col-md-4">
                        <ul>
                          <li>
                            <Link to="/female-ventriloquist-dummies">
                              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                              Female Ventriloquist Dummies
                            </Link>
                            <ul>
                              {/*<li>
                                <Link to="/products/amanda-deluxe-upgrade-dummy-with-moving-eyes">
                                  Amanda Deluxe Upgrade
                                </Link>
                              </li>*/}
                              <li>
                                <Link to="/products/amanda-standard-upgrade-dummy">
                                  Amanda Standard Upgrade
                                </Link>
                              </li>
                              {/*<li>
                                <Link to="/products/ashley-deluxe-upgrade-dummy-with-moving-eyes">
                                  Ashley Deluxe Upgrade
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/ashley-standard-upgrade-dummy">
                                  Ashley Standard Upgrade
                                </Link>
                              </li>*/}
                              <li>
                                <Link to="/products/ava-deluxe-upgrade-dummy-with-moving-eyes">Ava Deluxe Upgrade</Link>
                              </li>
                              <li>
                                <Link to="/products/ava-standard-upgrade-dummy">Ava Standard Upgrade</Link>
                              </li>
                              <li>
                                <Link to="/products/brittany-deluxe-upgrade-dummy-with-moving-eyes">Brittany Deluxe Upgrade</Link>
                              </li>
                              <li>
                                <Link to="/products/brittany-standard-upgrade-dummy">Brittany Standard Upgrade</Link>
                              </li>
                              {/*<li>
                                <Link to="/products/emma-deluxe-upgrade-dummy-with-moving-eyes">Emma Deluxe Upgrade</Link>
                              </li>*/}
                              <li>
                                <Link to="/products/emma-standard-upgrade-dummy">Emma Standard Upgrade</Link>
                              </li>
                              {/*<li>
                                <Link to="/products/isabella-deluxe-upgrade-dummy-with-moving-eyes">Isabella Deluxe Upgrade</Link>
                              </li>*/}
                              <li>
                                <Link to="/products/isabella-standard-upgrade-dummy">Isabella Standard Upgrade</Link>
                              </li>
                              <li>
                                <Link to="/products/jessica-deluxe-upgrade-dummy-with-moving-eyes">Jessica Deluxe Upgrade</Link>
                              </li>
                              <li>
                                <Link to="/products/jessica-standard-upgrade-dummy">Jessica Standard Upgrade</Link>
                              </li>
                              {/*<li>
                                <Link to="/products/lola-deluxe-upgrade-with-moving-eyes">Lola Deluxe Upgrade</Link>
                              </li>*/}
                              <li>
                                <Link to="/products/lola-standard-upgrade-dummy">Lola Standard Upgrade</Link>
                              </li>
                              {/*<li>
                                <Link to="/products/madison-deluxe-upgrade-dummy-with-moving-eyes">Madison Deluxe Upgrade</Link>
                              </li>*/}
                              <li>
                                <Link to="/products/madison-standard-upgrade-dummy">Madison Standard Upgrade</Link>
                              </li>
                              {/*<li>
                                <Link to="/products/olivia-deluxe-upgrade-dummy-with-moving-eyes">Olivia Deluxe Upgrade</Link>
                              </li>*/}
                              <li>
                                <Link to="/products/olivia-standard-upgrade-dummy">Olivia Standard Upgrade</Link>
                              </li>
                              {/*<li>
                                <Link to="/products/samantha-deluxe-upgrade-dummy-with-moving-eyes">Samantha Deluxe Upgrade</Link>
                              </li>*/}
                              <li>
                                <Link to="/products/samantha-standard-upgrade-dummy">Samantha Standard Upgrade</Link>
                              </li>
                              <li>
                                <Link to="/products/sarah-deluxe-upgrade-dummy-with-moving-eyes">Sarah Deluxe Upgrade</Link>
                              </li>
                              <li>
                                <Link to="/products/sarah-standard-upgrade-dummy">Sarah Standard Upgrade</Link>
                              </li>
                              {/*<li>
                                <Link to="/products/sophia-deluxe-upgrade-dummy-with-moving-eyes">Sophia Deluxe Upgrade</Link>
                              </li>*/}
                              <li>
                                <Link to="/products/sophia-standard-upgrade-dummy">Sophia Standard Upgrade</Link>
                              </li>
                            </ul>
                            {/* <ul>
                      <li>
                        <Link to="/basic-ventriloquist-dummies">
                          basic ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/standard-upgrade-ventriloquist-dummies">
                          standard upgrade ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/deluxe-upgrade-ventriloquist-dummies">
                          deluxe upgrade ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/super-deluxe-upgrade-ventriloquist-dummies">
                          super deluxe upgrade ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/hand-puppets">hand puppets</Link>
                      </li>
                      <li>
                        <Link to="/marionettes">marionettes</Link>
                      </li>
                      <li>
                        <Link to="/accessories-replacement-stuff">
                          accessories and replacement stuff for your dummy
                        </Link>
                      </li>
                      <li>
                        <Link to="/female-ventriloquist-dummies">
                          female ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/gramps-granny">
                          gramps and granny ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/professional-ventriloquist-dummies">
                          professional ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/books-dvds-cases-and-stands">
                          Ventriloquism books, dvds, cases and stands
                        </Link>
                      </li>
                      <li>
                        <Link to="/jeff-dunham-stuff">jeff dunham stuff</Link>
                      </li> 
                    </ul> */}
                          </li>
                          {/* </ul>
              </div>
              <div className="col-md-4 col-6">
                <ul> */}
                          {/*<li>
                            <Link to="/gramps-granny">
                              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                              Gramps & Granny Dummies
                            </Link>
                            <ul>
                              <li>
                                <Link to="/products/gramps-classic-ventriloquist-dummy">
                                  Gramps Classic - OUT OF STOCK
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/gramps-supreme-ventriloquist-dummy">
                                  Gramps Supreme - OUT OF STOCK
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/gramps-ultra-supreme-vent-dummy">
                                  Gramps Ultra Supreme - OUT OF STOCK
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/granny-classic-ventriloquist-dummy">
                                  Granny Classic - OUT OF STOCK
                                </Link>
                              </li>
                              <li>
                                <Link to="/products/granny-supreme-ventriloquist-dummy">Granny Supreme  - OUT OF STOCK</Link>
                              </li>
                              <li>
                                <Link to="/products/granny-ultra-supreme-dummy">Granny Ultra Supreme - OUT OF STOCK</Link>
                              </li>
                            </ul>
                          </li>*/}
                          <li>
                            <Link to="/professional-ventriloquist-dummies">
                              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                              Professional Ventriloquist Dummies
                            </Link>
                            {/* <ul>
                      <li>
                        <Link to="/basic-ventriloquist-dummies">
                          basic ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/standard-upgrade-ventriloquist-dummies">
                          standard upgrade ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/deluxe-upgrade-ventriloquist-dummies">
                          deluxe upgrade ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/super-deluxe-upgrade-ventriloquist-dummies">
                          super deluxe upgrade ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/hand-puppets">hand puppets</Link>
                      </li>
                      <li>
                        <Link to="/marionettes">marionettes</Link>
                      </li>
                      <li>
                        <Link to="/accessories-replacement-stuff">
                          accessories and replacement stuff for your dummy
                        </Link>
                      </li>
                      <li>
                        <Link to="/female-ventriloquist-dummies">
                          female ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/gramps-granny">
                          gramps and granny ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/professional-ventriloquist-dummies">
                          professional ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/books-dvds-cases-and-stands">
                          Ventriloquism books, dvds, cases and stands
                        </Link>
                      </li>
                      <li>
                        <Link to="/jeff-dunham-stuff">jeff dunham stuff</Link>
                      </li>
                    </ul> */}
                          </li>
                        </ul>
                      </div>

                    </div>
                  </div>
                </section>
                {/* <!-- Section ends --> */}


                {/*<p>The Carol Channing Basic Ventriloquist Dolls and Carol Channing Upgraded Ventriloquist Dummies have sold out and we do not currently anticipate having any more.</p>
                <p>Anticipated ship dates are subject to change as orders come in for figures in production and based on our figure makers' schedules.</p>
                <p>Please feel free to contact us if you are would like to check on availability of any dummies or products not listed here.</p>
                  <p>Because of circumstances beyond our control (such as delays in manufacturing, customs clearance, etc) we are not able to guarantee dates that the items will be back in stock, but the any estimates above are believed to be accurate at the time of posting.</p>*/}
              </div>
            </div>
          </div >
        </section >
      </Container >
    </Layout >
  )
}

export default Available
